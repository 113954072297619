import { theme } from '@/utils/theme';
import type { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete';

export type SearchableDropdownOption<T> = {
  value: T;
  label: string;
  description?: string;
};

export type BaseAutoCompleteProps<T> = MuiAutocompleteProps<
  SearchableDropdownOption<T>,
  false,
  false,
  true,
  any
>;

export type ModifiedAutoCompleteProps<T> = Omit<
  BaseAutoCompleteProps<T>,
  'onSelect' | 'renderInput' | 'options'
>;

export const classes = {
  autocomplete: 'w-full',
  paper: 'rounded-xl mt-2',
  listItem: 'transition-colors duration-100 mx-2 my-3 rounded-lg',
  optionWrapper: 'flex flex-col font-normal',
  label: 'cursor-pointer text-base text-grey-8',
  description: 'text-sm text-grey-7',
  addNewWrapper: 'fixed bg-white rounded-xl mt-2 shadow-card px-1 py-1',
  addNew:
    'flex justify-start items-center rounded-lg px-3 py-3 mx-2 my-1 text-grey-8 bg-white hover:bg-grey-2 cursor-pointer select-none',
  addIcon: 'text-grey-6 mr-4 cursor-pointer',
  addText: 'text-grey-8 cursor-pointer',
};

export const styles = {
  addNewWrapper: (box: DOMRect) => ({
    zIndex: 9999,
    left: box.left,
    top: box.top + box.height,
    width: box.width,
    height: 'auto',
  }),
  listBox: {
    '&': {
      padding: 0,
    },
  },
  listItem: {
    '&': {
      width: 'unset',
      backgroundColor: theme.palette['white'].main,
    },
    '&.MuiAutocomplete-option': {
      paddingTop: '0.5rem', // py-2
      paddingBottom: '0.5rem', // py-2
    },
    '&.MuiAutocomplete-option.Mui-focused, & .MuiAutocomplete-listbox .MuiAutocomplete-option[aria-selected="true"].Mui-focused, &.MuiAutocomplete-option[aria-selected="true"].Mui-focused':
      {
        backgroundColor: theme.palette['grey-2'].main,
      },
  },
};
