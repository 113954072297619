import { twMerge } from 'tailwind-merge';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { classes, styles } from './utils';

export type TimeListProps = {
  className?: string;
  title: string;
  options: { label: string; value: number }[];
  value?: number;
  onSelect: (value: number) => void;
};

export default function TimeList({
  className = '',
  title,
  options = [],
  value,
  onSelect,
}: TimeListProps) {
  return (
    <div className={twMerge(classes.wrapper, className)}>
      <div className={classes.title}>{title}</div>
      <List className={classes.list}>
        {options.map((option) => (
          <ListItem key={option.value} className={classes.listItem}>
            <ListItemButton
              autoFocus={option.value === value}
              sx={styles.listItemButton(option.value === value)}
              className={classes.listItemButton}
              onClick={() => onSelect(option.value)}>
              {option.label}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
}
