import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import TextField from '@mui/material/TextField';
import ListItem from '@mui/material/ListItem';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@/icons/AddIcon';
import { useToggle } from '@/hooks/useToggle';
import { isBlank } from '@/utils/strings';
import {
  type BaseAutoCompleteProps,
  classes,
  type ModifiedAutoCompleteProps,
  type SearchableDropdownOption,
  styles,
} from './utils';

export type { SearchableDropdownOption };

export interface SearchableDropdownProps<N extends string, T>
  extends ModifiedAutoCompleteProps<T> {
  disabled?: boolean;
  clearOnSelect?: boolean;
  autoFocus?: boolean;
  allowNew?: boolean;
  className?: string;
  wrapperClassName?: string;
  name: N;
  label: string;
  initialOptions: SearchableDropdownOption<T>[];
  onSearch: (search: string) => Promise<SearchableDropdownOption<T>[]>;
  onAddNew?: (search: string) => void;
  onSelect: (option: Record<N, SearchableDropdownOption<T>>) => void;
}

export default function SearchableDropdown<
  N extends string,
  T extends string | number,
>({
  name,
  label,
  initialOptions = [],
  onSelect,
  onAddNew,
  onSearch,
  wrapperClassName = '',
  className,
  disabled = false,
  clearOnSelect = false,
  autoFocus = false,
  allowNew = false,
  ...props
}: SearchableDropdownProps<N, T>) {
  const lastSearch = useRef<string | null>(null);
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState(initialOptions);
  const [isSearching, toggleSearching] = useToggle(false);

  useEffect(() => {
    setOptions(initialOptions);
  }, [initialOptions]);

  const handleSelect: BaseAutoCompleteProps<T>['onChange'] = (e, option) => {
    onSelect({ [name]: option } as Record<N, SearchableDropdownOption<T>>);
  };

  const handleSearch: BaseAutoCompleteProps<T>['onInputChange'] = async (
    e,
    search,
    reason
  ) => {
    setInputValue(search);
    if (reason === 'reset' && clearOnSelect) {
      setInputValue('');
      setOptions(initialOptions);
      return null;
    }
    if (search === lastSearch.current) {
      // Prevent running unnecessary searches
      return null;
    }
    lastSearch.current = search;
    toggleSearching(true);
    const newOptions = await onSearch(search);
    setOptions(newOptions);
    toggleSearching(false);
  };

  const renderAddNew = () => {
    if (
      allowNew &&
      !isSearching &&
      !isBlank(inputValue) &&
      options.length === 0 &&
      inputRef.current
    ) {
      if (!onAddNew) {
        console.warn('Missing prop: onAddNew()');
        return null;
      }
      const val = inputValue.trim();
      const box = inputRef.current.getBoundingClientRect();
      return (
        <ul className={classes.addNewWrapper} style={styles.addNewWrapper(box)}>
          <li className={classes.addNew} onClick={() => onAddNew(val)}>
            <AddIcon className={classes.addIcon} />
            <label className={classes.addText}>{`Add "${val}"`}</label>
          </li>
        </ul>
      );
    } else return null;
  };

  const renderOption: BaseAutoCompleteProps<T>['renderOption'] = (
    optionProps,
    option
  ) => {
    /*
      NOTE: Need to specify key separately to avoid error:
        A props object containing a "key" prop is being spread into JSX
      see https://stackoverflow.com/a/75968316/5839360
     */
    return (
      <ListItem
        {...optionProps}
        key={option.value}
        sx={styles.listItem}
        className={twMerge(optionProps.className, classes.listItem)}>
        <div className={classes.optionWrapper}>
          <label className={classes.label}>{option.label}</label>
          {option.description && (
            <p className={classes.description}>{option.description}</p>
          )}
        </div>
      </ListItem>
    );
  };

  const renderInput: BaseAutoCompleteProps<T>['renderInput'] = (params) => {
    return (
      <TextField
        {...params}
        fullWidth
        ref={inputRef}
        className={className}
        label={label}
        InputProps={{
          ...params.InputProps,
          autoFocus,
          endAdornment: (
            <>
              {isSearching ? (
                <CircularProgress color="inherit" size={20} />
              ) : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    );
  };

  return (
    <>
      <Autocomplete<SearchableDropdownOption<T>, false, false, true, any>
        {...props}
        freeSolo
        forcePopupIcon
        disabled={disabled}
        id={name}
        autoComplete
        ListboxProps={{ sx: styles.listBox }}
        slotProps={{
          paper: {
            className: classes.paper,
          },
        }}
        className={twMerge(classes.autocomplete, wrapperClassName)}
        getOptionLabel={(option) =>
          typeof option === 'string' ? option : option.label
        }
        isOptionEqualToValue={(option, value) => option.value === value.value}
        filterOptions={(x) => x}
        options={options}
        value={props.value}
        inputValue={inputValue}
        noOptionsText="No results"
        onChange={handleSelect}
        onInputChange={handleSearch}
        renderOption={renderOption}
        renderInput={renderInput}
      />
      {renderAddNew()}
    </>
  );
}
