import { theme } from '@/utils/theme';
import {
  type UndecoratedUser,
  type UndecoratedUserPreference,
} from '@witmetrics/api-client';

export interface ProfilePictureUser extends UndecoratedUser {
  userPreferences?: {
    [key: string]: UndecoratedUserPreference;
  } | null;
}

export const classes = {
  avatar: 'cursor-pointer',
};

export const styles = {
  shared: (size: number) => ({ height: size, width: size, fontSize: size / 2 }),
  avatar: {
    backgroundColor: theme.palette['grey-5'].main,
    color: theme.palette['grey-2'].main,
  },
  circularProgress: {
    color: theme.palette['purple-2'].main,
    animationDuration: '2s',
    '& .MuiCircularProgress-circle': {
      animationDuration: '2s',
    },
  },
};

export function getUserLabels(user: { firstname: string; lastname: string }) {
  const { firstname, lastname } = user;
  return {
    name: `${firstname} ${lastname}`,
    initials: `${firstname.slice(0, 1)}${lastname.slice(0, 1)}`.toUpperCase(),
  };
}
