import { useDispatch } from '@/store/useStore';
import { deleteTask as dispatchDeleteTask } from '@/store/slices/tasksSlice';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import { API } from '@/api';
import { showSuccessToaster } from '@/utils/toasters';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { classes } from './utils';

export type DeleteTaskDialogProps = {
  taskID: number;
  onClose: () => void;
};

export default function DeleteTaskDialog({
  taskID,
  onClose,
}: DeleteTaskDialogProps) {
  const dispatch = useDispatch();
  const { onApiError } = useAppState();
  const [isDeleting, toggleDeleting] = useToggle(false);

  const handleDelete = async () => {
    try {
      toggleDeleting(true);
      await API.Tasks.removeTask(taskID);
      dispatch(dispatchDeleteTask({ taskID }));
      showSuccessToaster('Task deleted');
      toggleDeleting(false);
      onClose();
    } catch (err) {
      toggleDeleting(false);
      onApiError(err, 'Error deleting task', () => {
        handleDelete();
      });
    }
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle title="Delete task" onClose={onClose} />
      <DialogBody className={classes.body}>
        Are you sure you want to delete this task?
        <br />
        This action cannot be undone.
      </DialogBody>
      <DefaultDialogActions
        disabled={isDeleting}
        pending={isDeleting}
        confirmLabel="Delete"
        pendingLabel="Deleting..."
        onCancel={onClose}
        onConfirm={handleDelete}
      />
    </Dialog>
  );
}
