import { twMerge } from 'tailwind-merge';
import { getProfilePicURL } from '@/api/getURL';
import Avatar, { AvatarProps } from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import {
  classes,
  getUserLabels,
  type ProfilePictureUser,
  styles,
} from './utils';

export interface ProfilePictureProps extends AvatarProps {
  user: ProfilePictureUser;
  size?: number;
  className?: string;
  isLoading?: boolean;
}

export default function ProfilePicture({
  user,
  size = 40,
  className = '',
  isLoading,
  children,
  ...props
}: ProfilePictureProps) {
  if (!user) return null;
  const labels = getUserLabels(user);
  const sx = styles.shared(size);
  const sharedProps = {
    sx,
    alt: labels.name,
    className: twMerge(classes.avatar, className),
    ...props,
  };
  const avatarProps = {
    ...sharedProps,
    sx: { ...sx, ...styles.avatar },
  };
  if (isLoading) {
    return (
      <CircularProgress
        sx={styles.circularProgress}
        thickness={2}
        size={size}
      />
    );
  }
  if (user && user.userPreferences?.profilePicSource) {
    return (
      <Avatar
        src={getProfilePicURL(user.userPreferences.profilePicSource.value)}
        {...sharedProps}
      />
    );
  }
  return <Avatar {...avatarProps}>{labels.initials}</Avatar>;
}
