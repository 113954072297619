import { useEffect, useRef } from 'react';
import { setDefaultOptions } from 'date-fns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import EventIcon from '@/icons/EventIcon';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import {
  buildHoursArray,
  buildMinutesArray,
  renderDateWithTime,
  renderTextDate,
} from '@/utils/time';
import Popover from '@mui/material/Popover';
import Paper from '@mui/material/Paper';
import TimePicker from '@/components/TimePicker';
import { useToggle } from '@/hooks/useToggle';
import type { WeekStartsOn } from '@/types/dates';
import { classes, styles } from './utils';

export type DateTimeInputProps = {
  className?: string;
  disablePast?: boolean;
  showTime?: boolean;
  closeOnSelect?: boolean;
  value: Date;
  dateFormat?: string;
  weekStartsOn?: WeekStartsOn;
  name: string;
  label: string;
  onChange: (value: { [key: string]: Date }) => void;
};

export default function DateTimeInput({
  className,
  disablePast = true,
  showTime = true,
  closeOnSelect = false,
  weekStartsOn = 0,
  dateFormat,
  value,
  name,
  label,
  onChange,
}: DateTimeInputProps) {
  const inputRef = useRef(null);
  const [isOpen, toggleOpen] = useToggle(false);
  const currentValue = value?.toISOString();

  setDefaultOptions({ weekStartsOn });

  useEffect(() => {
    // Default to 24 hours from now
    if (!value) {
      let time = new Date();
      time.setDate(time.getDate() + 1);
      // Ignore seconds/ms
      time.setSeconds(0, 0);
      // Round to the nearest increment of 5
      if (showTime) {
        time.setMinutes(Math.round(time.getMinutes() / 5) * 5);
      }
      onChange({ [name]: time });
    }
  }, [value]);

  const handleSelectDate = (val: Date) => {
    let time = new Date(value.toISOString());
    time.setFullYear(val.getFullYear(), val.getMonth(), val.getDate());
    onChange({ [name]: time });
    if (closeOnSelect) toggleOpen(false);
  };

  const handleSelectTime = (update: { hours?: number; minutes?: number }) => {
    let time = new Date(value.toISOString());
    if (update.hasOwnProperty('hours')) time.setHours(update.hours!);
    if (update.hasOwnProperty('minutes')) time.setMinutes(update.minutes!);
    onChange({ [name]: time });
    if (closeOnSelect) toggleOpen(false);
  };

  return (
    <FormControl className={className} size="small" variant="outlined">
      <InputLabel htmlFor={name}>{label}</InputLabel>
      <OutlinedInput
        ref={inputRef}
        id={name}
        label={label}
        value={
          showTime
            ? renderDateWithTime(currentValue, dateFormat)
            : renderTextDate(currentValue, dateFormat)
        }
        onClick={() => toggleOpen()}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              edge="end"
              aria-label="toggle calendar visibility"
              sx={styles.iconButton}>
              <EventIcon />
            </IconButton>
          </InputAdornment>
        }
      />
      <Popover
        open={isOpen}
        anchorEl={inputRef.current}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        onClose={() => toggleOpen(false)}>
        <Paper className={classes.paper}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateCalendar
              showDaysOutsideCurrentMonth
              disablePast={disablePast}
              sx={styles.calendar}
              value={value}
              onChange={handleSelectDate}
            />
          </LocalizationProvider>
          {showTime && (
            <TimePicker
              hours={value.getHours()}
              minutes={value.getMinutes()}
              hoursOptions={buildHoursArray(true)}
              minutesOptions={buildMinutesArray(5)}
              onChange={handleSelectTime}
            />
          )}
        </Paper>
      </Popover>
    </FormControl>
  );
}
