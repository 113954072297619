import { useEffect, useState } from 'react';
import {
  useActiveAccount,
  useCurrentUser,
  useDateFormatPreference,
  useDispatch,
  useUnisonProject,
  useUnisonProjectTaskFromTaskID,
  useWeekStartsOnPreference,
} from '@/store/useStore';
import {
  addTask as dispatchAddTask,
  updateTask as dispatchUpdateTask,
} from '@/store/slices/tasksSlice';
import { addUnisonProjectTask as dispatchAddUnisonProjectTask } from '@/store/slices/unisonProjectTasksSlice';
import { buildUserOption } from '@/utils/filters';
import SearchableDropdown from '@/components/SearchableDropdown';
import DateTimeInput from '@/components/DateTimeInput';
import Dialog, {
  DefaultDialogActions,
  DialogBody,
  DialogTitle,
} from '@/components/Dialog';
import TextField from '@/components/TextField';
import { useAppState } from '@/providers/AppStateProvider';
import { useToggle } from '@/hooks/useToggle';
import { showSuccessToaster } from '@/utils/toasters';
import {
  buildProjectOption,
  buildTaskData,
  classes,
  createNewTask,
  fetchMatchingProjects,
  fetchPracticeUsers,
  getInitialDate,
  searchAssignedTo,
  updateTask,
  validateFields,
} from './utils';

export type TaskDialogProps = {
  taskID?: number;
  projectID?: number | null;
  onClose: () => void;
};

export default function UnisonProjectTaskDialog({
  taskID,
  projectID,
  onClose,
}: TaskDialogProps) {
  const dispatch = useDispatch();
  const { onApiError, activeCalendarDate } = useAppState();
  const weekStartsOn = useWeekStartsOnPreference();
  const dateFormat = useDateFormatPreference();
  const unisonProjectTask = useUnisonProjectTaskFromTaskID(taskID);
  const initialProject = useUnisonProject(projectID);
  const currentUser = useCurrentUser();
  const activeAccount = useActiveAccount();
  const [practiceUsers, setPracticeUsers] = useState([
    buildUserOption(currentUser!),
  ]);
  const [project, setProject] = useState(
    initialProject ? buildProjectOption(initialProject) : null
  );
  const [assignedTo, setAssignedTo] = useState(buildUserOption(currentUser!));
  const [dueAt, setDueAt] = useState<Date>(
    getInitialDate(activeCalendarDate, unisonProjectTask?.task)
  );
  const [description, setDescription] = useState('');
  const [invalidFields, setInvalidFields] = useState<string[]>([]);
  const [isSaving, toggleSaving] = useToggle(false);

  useEffect(() => {
    if (activeAccount) handleFetchPracticeUsers();
  }, [activeAccount]);

  useEffect(() => {
    if (unisonProjectTask) fillFields();
  }, [unisonProjectTask]);

  const fillFields = () => {
    const { task, unisonProject } = unisonProjectTask!;
    setDueAt(new Date(task!.dueAt));
    setProject(buildProjectOption(unisonProject!));
    setAssignedTo(buildUserOption(task!.assignedTo));
    setPracticeUsers([buildUserOption(task!.assignedTo)]);
    setDescription(task!.description);
  };

  const handleSave = async () => {
    try {
      toggleSaving(true);
      const invalidFields = validateFields({
        project,
        assignedTo,
        description,
      });
      if (invalidFields.length > 0) return setInvalidFields(invalidFields);
      const data = buildTaskData({ dueAt, assignedTo, description });
      if (taskID) {
        const updatedTask = await updateTask(taskID, data);
        dispatch(dispatchUpdateTask(updatedTask));
        showSuccessToaster('Task updated');
      } else {
        const result = await createNewTask(project!.value, data);
        dispatch(dispatchAddTask(result.task));
        dispatch(dispatchAddUnisonProjectTask(result.unisonProjectTask));
        showSuccessToaster('Task created');
      }
      onClose();
    } catch (err) {
      toggleSaving(false);
      onApiError(err, 'Error saving task', () => handleSave());
    }
  };

  const isSaveDisabled = () => {
    return (
      isSaving ||
      validateFields({ project, assignedTo, description }).length > 0
    );
  };

  const handleFetchPracticeUsers = async () => {
    try {
      const practiceID = activeAccount!.accountID;
      const users = await fetchPracticeUsers(practiceID);
      setPracticeUsers(users);
    } catch (err) {
      onApiError(err, 'Error fetching practice users', () => {
        handleFetchPracticeUsers();
      });
    }
  };

  const handleSearchAssignedTo = async (searchQuery: string) => {
    return searchAssignedTo(searchQuery, practiceUsers);
  };

  return (
    <Dialog className={classes.dialog} onClose={onClose}>
      <DialogTitle
        title={taskID ? 'Edit Task' : 'New Task'}
        onClose={onClose}
      />
      <DialogBody className={classes.body}>
        <DateTimeInput
          disablePast={false}
          className={classes.input}
          dateFormat={dateFormat}
          weekStartsOn={weekStartsOn}
          name="dueAt"
          label="Deadline"
          value={dueAt}
          onChange={({ dueAt }) => setDueAt(dueAt)}
        />
        <SearchableDropdown
          className={classes.input}
          name="assignedTo"
          label="Assigned To"
          value={assignedTo}
          initialOptions={practiceUsers}
          onSearch={handleSearchAssignedTo}
          onSelect={(update) => setAssignedTo(update.assignedTo)}
        />
        <SearchableDropdown
          className={classes.input}
          disabled={!!taskID}
          name="project"
          label="Select a Project"
          value={project}
          initialOptions={project ? [project] : []}
          onSearch={fetchMatchingProjects}
          onSelect={(update) => setProject(update.project)}
        />
        <TextField
          multiline
          minRows={3}
          maxRows={5}
          className={classes.input}
          name="description"
          label="Description"
          value={description}
          onChange={({ description }) => setDescription(description)}
        />
      </DialogBody>
      <DefaultDialogActions
        disabled={isSaveDisabled()}
        pending={isSaving}
        confirmLabel="Save"
        pendingLabel="Saving..."
        onCancel={onClose}
        onConfirm={handleSave}
      />
    </Dialog>
  );
}
