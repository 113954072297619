import { theme } from '@/utils/theme';

export const classes = {
  wrapper: 'my-4 flex flex-col justify-start items-center',
  title: 'font-medium text-grey-8',
  list: 'max-h-[300px] overflow-y-auto',
  listItem: 'px-0',
  listItemButton: 'px-6 rounded-lg transition-colors duration-100',
};

export const styles = {
  listItemButton: (isActive: boolean) => ({
    '&:hover': {
      color: theme.palette[isActive ? 'white' : 'grey-8'].main,
      backgroundColor: theme.palette[isActive ? 'purple-2' : 'grey-2'].main,
    },
    '&': {
      color: theme.palette[isActive ? 'white' : 'grey-8'].main,
      backgroundColor: theme.palette[isActive ? 'purple-2' : 'white'].main,
    },
  }),
};
