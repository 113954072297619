import { twMerge } from 'tailwind-merge';
import TimeList from './TimeList';
import { classes } from './utils';

export type TimePickerProps = {
  className?: string;
  hours?: number;
  minutes?: number;
  hoursOptions: { label: string; value: number }[];
  minutesOptions: { label: string; value: number }[];
  onChange: (time: { hours?: number; minutes?: number }) => void;
};

export default function TimePicker({
  hours,
  minutes,
  hoursOptions,
  minutesOptions,
  onChange,
  className = '',
}: TimePickerProps) {
  return (
    <div className={twMerge(classes.wrapper, className)}>
      <TimeList
        title="Hours"
        className={classes.hours}
        value={hours}
        options={hoursOptions}
        onSelect={(hours) => onChange({ hours })}
      />
      <TimeList
        title="Minutes"
        className={classes.minutes}
        value={minutes}
        options={minutesOptions}
        onSelect={(minutes) => onChange({ minutes })}
      />
    </div>
  );
}
